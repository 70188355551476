<template>
  <v-theme-provider theme="dark" with-background>
    <div :class="theme">
      <v-locale-provider :rtl="isRtl()" :dir="isRtl() ? 'rtl' : 'ltr'">
        <slot />
      </v-locale-provider>
    </div>
  </v-theme-provider>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import ui from '@/composables/uiTheme';

export default {
  props: {
    theme: {
      type: String,
      required: true,
    },
  },
  setup() {
    // RTL Setup
    const i18nLocale = useI18n();
    const rtlDefault = i18nLocale.locale.value === 'ar';
    const rtl = ui.rtl();
    const isRtl = () => {
      if (rtl.value !== undefined) {
        return rtl.value;
      }
      return rtlDefault;
    };

    // Dark Light Setup
    const defaultMode = ref('');
    const themeMode = ui.themeMode();

    onMounted(() => {
      // Set theme
      // defaultMode.value = localStorage.getItem('awroraDarkMode') || 'dark';
      defaultMode.value = 'dark';
    });

    return {
      defaultMode,
      themeMode,
      isRtl,
    };
  },
};
</script>
