<script setup lang="ts">
const globalLoading = useLoadingStore()

</script>
<template>
    <v-dialog class="global-loading" v-model="globalLoading.isLoading" max-width="320" persistent>
        <div class="bg-[#212329]   flex flex-col items-center justify-center w-[108px] h-[108px] rounded-2xl p-4"
            style="margin: auto !important">
            <v-progress-circular color="primary" indeterminate="disable-shrink"></v-progress-circular>
            <div class="msg mt-4">
                {{ globalLoading.message }}
            </div>
        </div>
    </v-dialog>
</template>